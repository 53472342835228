import _setPrototypeOf from "./setPrototypeOf.js";
var exports = {};
var setPrototypeOf = _setPrototypeOf;
function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function");
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      writable: true,
      configurable: true
    }
  });
  Object.defineProperty(subClass, "prototype", {
    writable: false
  });
  if (superClass) setPrototypeOf(subClass, superClass);
}
exports = _inherits, exports.__esModule = true, exports["default"] = exports;
export default exports;
export const __esModule = exports.__esModule;